
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from './cell'
  import { Consignment } from '@/entities/purchase'

@Component
  export default class StockLeadReceived extends Cell {
  stockData: {
    activeLeads: number,
    totalLeads: number,
    lastWeek: number,
  } | null = null

  @Watch('item', { immediate: true, deep: true })
  async onStockId (value: Consignment) {
    const stockId = value?.negotiation?.inspection?.enablement?.stock?.id
    if (stockId) {
      const data = await this.getLeadReceivedStock(stockId)

      if (data?.activeLeads) {
        this.stockData = data
      } else {
        this.stockData = {
          activeLeads: 0,
          totalLeads: 0,
          lastWeek: 0,
        }
      }
    }
  }
  }
